import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const SalesPage = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { entrypoint } = parsedLocation;
  useRedirectHandler(() => {
    if (entrypoint === "premiumMessagingPresale") {
      return "https://home.classdojo.com/#/messages?openModal=premiumMessagingPresale";
    } else if (entrypoint === "biannual") {
      return "https://home.classdojo.com/#/subscription/biannual-promo";
    } else {
      return "https://www.classdojo.com/plus/";
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default SalesPage;
